'use client'
import Link from 'next/link';
import { DrupalMenuLinkContent } from 'next-drupal';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { VersionedUrlManager, makeDrupalMenuLinkContent } from '@/lib/utils';
import { DrupalNodeBasicPage, DrupalNodeRelease, DrupalTaxonomyTermProduct } from '@/types';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import { useContentFromRoute } from '@/contexts/ContentRouteProvider';
import { Fragment } from 'react';
import { getLandingPage } from '@/lib/drupal/util';

interface BreadcrumbProps {
    menu: DrupalMenuLinkContent[] | null
    product: DrupalTaxonomyTermProduct
    relatedProducts?: DrupalTaxonomyTermProduct[]
    className?: string
    version?: string
}

interface StaticText {
    title: string
    id: string
    url?: string
    route?: {
        name: string
    }
}

export function Breadcrumb({ menu, className }: {
    menu?: DrupalMenuLinkContent[]
    className?: string
}) {
    const pathname = usePathname()
    const { resource } = useContentFromRoute<DrupalNodeBasicPage|DrupalNodeRelease>()
    if (!menu) {
        return <></>
    }
    if (!resource?.field_product) {
        return <></>
    }
    const product = resource.field_product
    const base_path = getLandingPage(product)
    if (!pathname.startsWith(base_path)) {
        console.warn(`Breadcrumb could not render: ${pathname} is not in ${base_path}`)
        return <></>
    }

    const slug = pathname.replace(base_path, '').split('/').filter(s => s.length > 0)
    const menuTrail = getActiveTrailFromMenu(menu, slug, base_path, pathname)
    const trail = decorateActiveTrail(menuTrail, resource)
    
    return <ul className={classNames('gap-1 mb-4 items-center', className)}>
        {trail.map((item, i) => (
            <Fragment key={item.id}>
            <li key={item.id}>
                {item.route.name == 'add-on' ? (
                    <span
                        className=''>
                            {item.title}
                        </span>
                ): (
                    <Link 
                        href={item.url}
                        className='text-blue-600 text-ellipsis hover:bg-gray-400 rounded p-2 block'
                        >{item.title}</Link>
                )}
            </li>
            { (i+1) < (trail.length) && <li>
                <ChevronRightIcon className='ml-2 w-6 overflow-visible text-gray-600' />
            </li>}
            </Fragment>
        ))}
    </ul>
}

function decorateActiveTrail(trail: ReturnType<typeof getActiveTrailFromMenu>, resource: DrupalNodeBasicPage | DrupalNodeRelease) {
    if (resource.field_product) {
        const product = resource.field_product
        trail.unshift(makeDrupalMenuLinkContent({
            title: product.name,
            url: getLandingPage(product),
            route: {
                name: 'product'
            },
        }))

        // This means the product is an add-on.
        if (product.parent[0].id != "virtual") {
            trail.unshift(makeDrupalMenuLinkContent({
                title: 'Add Ons',
                id: 'add_ons',
                route: { name: 'add-on' }
            }))
            trail.unshift(makeDrupalMenuLinkContent({
                title: product.parent[0].name,
                url: getLandingPage(product.parent[0]),
                route: {
                    name: 'product'
                },
            }))
        }
    }

    trail.unshift(makeDrupalMenuLinkContent({
        title: 'Home',
        id: 'home',
        description: '',
        route: {
            name: '<front>',
            parameters: {}
        },
        url: '/',
    }))

    return trail
}

function getActiveTrailFromMenu(menu: DrupalMenuLinkContent[], slug: string[], basePath: string, currentPath: string) {
    const trail = slug.reduce((p, c) => {
        const { menu, trail, pathname } = p

        if (!menu) {
            return p
        }

        const path = [pathname, c].filter(p => p.length > 0).join('/')
        const items = menu
            .filter(i => !i.url.startsWith(currentPath))
            .filter(i => i.url != basePath && i.url.startsWith(`${basePath}/${path}`))

        // Did not find any menu items in the active trail.
        if (items.length == 0) {
            return p
        }

        trail.push({
            ...items[0],
            // Remove the items array to make the breadcrumb trail lighter.
            items: []
        })

        return { 
            menu: items[0].items, 
            trail, 
            pathname: path
        }
    }, {
        menu: menu as DrupalMenuLinkContent[] | undefined,
        trail: [] as DrupalMenuLinkContent[],
        pathname: ''
    });
    return trail.trail
}
