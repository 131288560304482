import { DrupalMenuLinkContent } from "next-drupal"


export function formatDate(input: string): string {
    const date = new Date(input)
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    })
  }
  
// export function absoluteUrl(input: string) {
//   return `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${input}`
// }


export function makeDrupalMenuLinkContent(props: any): DrupalMenuLinkContent {
    return {
      title: props.title ?? 'Home',
      description: props.description ?? '',
      enabled: true,
      expanded: false,
      id: props.id ?? props.title.toLowerCase().replace('/ /_/'),
      menu_name: '',
      meta: {},
      options: {},
      parent: '',
      provider: '',
      route: props.route ?? {
          name: '',
          parameters: {}
      },
      type: '',
      url: props.url ?? '/',
      weight: '0',
      items: props.items ?? []
  }
}

export class VersionedUrlManager {
  private defaultPosition: number | null = null
  private pathBase: string | null = null
  constructor(private version?: string) {}

  public getVersionPosition(path: string, version?: string): number | null {
    version = version ?? this.version
    if (version === undefined) {
      return null
    }
    return path.split('/').indexOf(version ?? this.version) ?? null
  }

  public setDefaultPosition(position: number | null): void {
    this.defaultPosition = position
  }

  public setPathBase(path: string): void {
    this.pathBase = path
  }

  public translatePath(path: string, position?: number | null): string {
    if (this.version === undefined) {
      return path
    }
    position = position ?? this.defaultPosition;
    if (position === null) {
      return path
    }
    // Split path on '/' and replace index at position with version
    return path.split('/').map((segment, index) => {
      if (index === position) {
        return this.version
      }
      return segment
    }).join('/')
  }
}