import { usePathname } from 'next/navigation';
import Link from 'next/link';
import { DrupalMenuLinkContent } from 'next-drupal';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { Menu as HeadlessUiMenu } from '@headlessui/react';
import { HTMLAttributes, useState } from 'react';

interface MenuProps extends HTMLAttributes<any> {
    menu?: DrupalMenuLinkContent[]
    query?: string
}

export function Menu({ menu, ...props }: MenuProps) {
    const pathname = usePathname()

    if (!menu?.length) {
        return null;
    }

    return (
        <nav data-cy="menu" {...props}>
            {menu.map((link, i) => {
                const isActive = pathname === link.url
                return (link.items && link.items.length > 0) ? (
                    <DropDown 
                        key={`menu-item-${i}`} 
                        buttonLabel={link.title} 
                        items={link.items ?? []} 
                        href={link.url} 
                        route={link.route ?? null} 
                        level={1} />
                ) : (
                    <Link key={`menu-item-${i}`} className={`${isActive && 'bg-gray-500'} transition-colors duration-300 hover:bg-gray-500 p-2 font-bold rounded-lg`} href={link.url}>{link.title}</Link>
                )
            })}
        </nav>
    );
}

const MenuItem = ({items, href, route, level, label}: {
    items?: DrupalMenuLinkContent[],
    href: string
    route?: {
        name: string
    }
    level: number
    label: string
}) => {
    const pathname = usePathname()
    const isActive = href.startsWith(pathname)

    if (!items || items.length < 1) {
        return <Link 
            className={`${isActive && 'bg-gray-500'} transition-colors duration-300 hover:bg-gray-500 p-2 font-bold rounded-lg`} 
            href={href}>
                {label}
            </Link>
    }

}

interface DropDownProps {
    buttonLabel: string,
    items: DrupalMenuLinkContent[],
    href: string,
    route?: {
        name: string
    }
    level: number
}

const DropDown = (props: DropDownProps) => {
    const pathname = usePathname()
    const isActiveLink = pathname === props.href
    const isActivePath = props.href !== '/' ? pathname.indexOf(props.href) === 0 : false
    const [isActiveMenu, setIsActiveMenu] = useState<string>('');

    const font = props.level == 1 ? 'font-bold' : 'text-black'
    const captureClick = (url:string) => {
        setIsActiveMenu(url)
    }

    return (
        <div className='flex flex-col'>
            <HeadlessUiMenu>
                {({ open }) => (
                    <>
                        <HeadlessUiMenu.Button className={`${isActiveLink && 'bg-gray-500 font-bold'} flex hover:bg-gray-500 px-2 py-1 my-1 rounded transition-colors duration-300`}>
                            {!props.route?.name.length ? (
                                <span className={`flex-grow text-left ${font}`}>{props.buttonLabel}</span>
                            ) : (
                                <Link href={props.href} className={`flex-grow text-left ${font}`}>{props.buttonLabel}</Link>
                            )}

                        { !open && !isActivePath && !isActiveMenu ? (
                            <>
                                <ChevronDownIcon className='print:hidden ml-2 mt-1 w-4 overflow-visible' />
                            </>
                        ) : !isActivePath && (
                                <ChevronUpIcon className='print:hidden ml-2 mt-1 w-4 overflow-visible' />
                        )}
                        </HeadlessUiMenu.Button>
                        { (open || isActivePath || isActiveMenu) && (
                            <HeadlessUiMenu.Items className="flex flex-col ps-5" static={true}>
                            { props.items.map((link) => {
                                    const isActiveLink = (pathname === link.url)
                                    return (
                                        <HeadlessUiMenu.Item key={link.id}>
                                            {link.items && link.items.length > 0 ? (
                                                <DropDown 
                                                    key={`menu-item-${link.id}`} 
                                                    buttonLabel={link.title} 
                                                    items={link.items ?? []} 
                                                    href={link.url} 
                                                    route={link.route ?? null} 
                                                    level={props.level + 1} />
                                            ) : (
                                                 <Link 
                                                    className={`${(isActiveLink || isActiveMenu == link.url) && 'bg-gray-500 font-bold'} px-2 py-1 my-1 text-black hover:bg-gray-500 transition-colors duration-300 rounded`} 
                                                    href={link.url} onClick={() => captureClick(link.url)}
                                                >{link.title}</Link>
                                            )}
                                        </HeadlessUiMenu.Item>
                                    )
                                })}
                            </HeadlessUiMenu.Items>
                        )}
                    </>
                )}
            </HeadlessUiMenu>
        </div>
    );
}