'use client'

import { preferences, setVersionPreference } from "@/redux/features/versions";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { Listbox } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { usePathname, useRouter } from "next/navigation";
import { Fragment } from "react";

// React component to select versions from a dropdown box
export function VersionSelector({
  versions,
  supportedVersions,
  versionChoice,
  productName
}:
  {
    versions: string[],
    supportedVersions: string[],
    productName: string,
    versionChoice: string
  }
  ) {

  // Retrieve the version preferences from redux.
  const versionPreferences = useAppSelector(preferences)
  const preference = versionPreferences[productName] ?? versionChoice

  const dispatch = useAppDispatch()

  const router = useRouter()
  const pathname = usePathname()

  function setVersion(newVersion: string) {
    dispatch(setVersionPreference({
      product: productName,
      version: newVersion
    }))
    router.push(pathname + '?v=' + newVersion)
  }

  return (
    <>
    <Listbox 
      value={preference} 
      onChange={setVersion}>
      <Listbox.Button className="text-left print:hidden w-full p-3 mb-2 rounded border-gray-500 border bg-white flex">
        <span className="flex-grow">Version: {preference}</span> <ChevronUpDownIcon className='ml-2 w-6 overflow-visible text-gray-600 self-end' />
      </Listbox.Button>
      <div className="relative z-10">
      <Listbox.Options className="absolute text-left print:hidden w-full p-3 mb-2 rounded border-gray-500 border bg-white">
        {versions.map((version) => (
          <Listbox.Option
          disabled={supportedVersions.indexOf(version) === -1}
          as={Fragment}
          key={version}
          value={version}
        >
          {({active}) => (
            <li className={`${active ? 'bg-gray-300' : ''} ${supportedVersions.indexOf(version) === -1 ? 'text-gray-600' : ''} p-3 mb-2 rounded cursor-pointer`}>{version}</li>
          )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
      </div>
    </Listbox>
    </>
  );
}