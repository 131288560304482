'use client'

import { DrupalMenuLinkContent, DrupalNode } from 'next-drupal';
import React, { ReactNode, useEffect, useState } from 'react';
import { Menu } from '../../block/Menu';
import { getSidebarMenu } from '@/lib/drupal/get-menus.server';
import { useAppSelector } from '@/redux/hooks';
import { preferences } from '@/redux/features/versions';


export const SidebarVersionedMenu = (props: {
  resource: DrupalNode
  product: string
  defaultMenu: DrupalMenuLinkContent[]
  children?: ReactNode
  versions?: string[]
}) => {
  // const menus = useRef<DrupalMenuLinkContent[]>(props.defaultMenu)
  const [menus, setMenu] = useState(props.defaultMenu)

  const versionPreferences = useAppSelector(preferences)
  const preference = versionPreferences[props.product] ?? null

  useEffect(() => {
    if (preference !== null) {
      getSidebarMenu(props.resource, preference).then((menu: DrupalMenuLinkContent[]) => setMenu(menu))
    }
  }, [preference, props.resource])

  return (
    <div className="pr-12">
      {props.children}
      <Menu menu={menus} className="flex flex-col text-navy-600" id="sidebar-menu" />
    </div>
  )
}